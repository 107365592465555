import cx from 'classnames'
import { Fragment, useState } from 'react'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
  Dialog,
  DialogPanel,
} from '@headlessui/react'
import {
  ShoppingCartIcon,
  Bars3Icon,
  XMarkIcon,
  UserCircleIcon,
  UserIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'
import popularTest from '../public/images/product/qfit_bowel_cancer_test_box_thumb.jpg'
import SelphLogo from '../public/images/logo.svg?svgr'
import SelphLogoWhite from '../public/images/logoWhite.svg?svgr'
import { useUi } from '../components/ui/use-ui'
import TrustpilotBox from './Trustpilot/trustpilot-box'
import { ProductLink } from './product/product-link'
import { useBasket } from './checkout/use-basket'
import { useUser } from './user/use-user'
import AlgoliaSearch from './Algolia/algolia-search'
import * as UI from '@/ui'
import { ProductType } from '@/gql'

export type HeaderBgColours = 'blue' | 'white' | 'transparent'

type HeaderProps = {
  darkBg?: boolean
  bgColour?: HeaderBgColours
}

export const Header = ({ darkBg = false, bgColour = 'transparent' }: HeaderProps) => {
  const bgColours = {
    blue: 'bg-selphBlue-300',
    white: 'bg-white',
    transparent: 'bg-transparent',
  }

  const ui = useUi({
    styles: {
      color: { options: bgColours, selected: bgColour },
    },
    name: 'Header',
  })

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)
  const { numberOfLineItems, destroyBasket } = useBasket()
  const { isLoggedIn, logout } = useUser()

  return (
    <>
      {/* Mobile Menu */}
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-selphGrey-700 fixed inset-0 bg-opacity-75" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full opacity-0"
              enterTo="translate-x-0 opacity-100"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0 opacity-100"
              leaveTo="-translate-x-full opacity-0"
            >
              <DialogPanel className="bg-selphGrey-200 relative flex w-full max-w-xs flex-1 flex-col pb-4 pt-5">
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>

                <div className="flex flex-shrink-0 items-center px-4">
                  <UI.Link to="root">
                    <SelphLogo width={100} height={32} />
                    <span className="sr-only">Home</span>
                  </UI.Link>
                </div>

                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <UI.Block gap="small" className="px-2">
                    {[...navigation.desktopPages, ...navigation.mobilePages].map((page) => (
                      <UI.Block gap="small" key={page.name}>
                        <UI.Link
                          key={page.name}
                          to={page.to}
                          color="navbar"
                          className="flex items-center font-light"
                          onClick={() => setSidebarOpen(false)}
                        >
                          {({ active }) => (
                            <div
                              className={cx(
                                {
                                  'border-l-hotPink-500 cursor-default border': active,
                                  'border-l-transparent': !active,
                                },
                                'w-full border-0 border-l-4 pl-2 text-lg',
                              )}
                            >
                              <span className={cx({ 'link-hover-pink': !active })}>{page.name}</span>
                            </div>
                          )}
                        </UI.Link>
                      </UI.Block>
                    ))}
                    <UI.Block gap="none" className="pt-5">
                      <span className="text-selphGrey-600 block text-sm uppercase">POPULAR TESTS</span>

                      <div className="mx-auto max-w-xs overflow-hidden">
                        <div className="relative h-32 w-full">
                          <ProductLink
                            slug="q-fit-bowel-cancer-test"
                            type={ProductType.LabTest}
                            onClick={() => setSidebarOpen(false)}
                          >
                            <UI.Image
                              src={popularTest}
                              alt="Popular test"
                              fill
                              placeholder="blur"
                              sizes="320px"
                              className="rounded-b-lg object-cover object-center"
                            />
                          </ProductLink>
                        </div>

                        <div className="h-full py-2">
                          <UI.Block gap="small">
                            <ProductLink
                              slug="qfit-bowel-cancer-test"
                              type={ProductType.LabTest}
                              onClick={() => setSidebarOpen(false)}
                            >
                              <span className="text-selphBlue-600 text-base font-semibold">
                                qFIT Bowel Cancer Screening Test
                              </span>
                            </ProductLink>

                            <UI.Paragraph size="small" color="darkGrey">
                              Got gut symptoms or just want to screen for bowel cancer? The qFIT test - used in the NHS
                              and the Bowel Cancer Screening Programme - picks up blood in the stool which can be caused
                              by bowel cancer.
                            </UI.Paragraph>
                          </UI.Block>
                        </div>
                      </div>
                    </UI.Block>
                  </UI.Block>
                </div>
              </DialogPanel>
            </TransitionChild>

            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Mobile Algolia Search */}
      <Transition show={searchOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSearchOpen}>
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="bg-selphGrey-700 fixed inset-0 bg-opacity-75" />
          </TransitionChild>

          <div className="fixed inset-y-0 left-10 right-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <DialogPanel className="bg-selphGrey-200 relative flex w-full flex-1 flex-col pb-4 pt-5">
                <div className="absolute -left-10 top-0 py-3">
                  <button
                    type="button"
                    className="flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSearchOpen(false)}
                  >
                    <span className="sr-only">Close searchbar</span>
                    <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto">
                  <AlgoliaSearch mobileSearch={{ searchOpen, setSearchOpen }} />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Main Menu */}
      <div className={`relative z-20 py-2 ${ui.className} `} id="navbar">
        <div className="mx-auto flex max-w-7xl flex-col gap-y-2 px-2 sm:px-4 md:px-6">
          <div className="flex h-5 w-full items-baseline justify-center md:h-8 md:justify-between">
            <div className="md:flex-1">
              {/* Trustbox */}
              <TrustpilotBox boxStyle="microHeader" darkBg={darkBg} />
            </div>

            {/* Algolia Search */}
            <div className="flex h-8 justify-center max-md:hidden lg:flex-1">
              <AlgoliaSearch />
            </div>

            {/* Menu Items */}
            <div className="flex justify-end gap-x-3 text-sm max-md:hidden md:flex-1 lg:text-base">
              {...navigation.mobilePages.map((page) => (
                <UI.Link
                  key={page.name}
                  to={page.to}
                  className={cx(darkBg ? 'text-white' : 'text-selphBlue-600', 'whitespace-nowrap')}
                >
                  {page.name}
                </UI.Link>
              ))}
            </div>
          </div>

          <div className="relative flex items-center justify-between gap-x-2 gap-y-2">
            <div className="flex gap-x-2">
              {/* Menu Icon */}
              <div className="z-10 flex items-center md:hidden">
                <button
                  type="button"
                  className={cx(
                    darkBg ? 'text-white' : 'text-selphBlue-600',
                    'focus:ring-hotPink-500 hover:ring-hotPink-500 hover:ring-2 focus:outline-none focus:ring-2 md:hidden',
                  )}
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon className="relative h-6 w-6 stroke-1" aria-hidden="true" />
                </button>
              </div>

              {/* Logo */}
              <div className="flex items-center justify-center md:items-stretch md:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <UI.Link to="root">
                    {darkBg ? (
                      <SelphLogoWhite className="w-16 md:w-20 lg:w-24" />
                    ) : (
                      <SelphLogo className="w-16 md:w-20 lg:w-24" />
                    )}
                    <span className="sr-only">Home</span>
                  </UI.Link>
                </div>
              </div>
            </div>

            {/* Navigation */}
            <div className="hidden flex-wrap items-center justify-center px-5 pb-1 md:flex md:space-x-5 lg:justify-around">
              {navigation.desktopPages.map((item) => (
                <UI.Link
                  key={item.name}
                  to={item.to}
                  color="navbar"
                  className={cx(
                    darkBg ? 'text-white' : 'text-selphBlue-600',
                    'link-hover-pink whitespace-nowrap text-base lg:text-xl',
                  )}
                >
                  {item.name}
                </UI.Link>
              ))}
            </div>

            <div className="flex items-center justify-end gap-x-2">
              {/* Profile dropdown */}
              {isLoggedIn ? (
                <Menu as="div" className="relative z-20">
                  <MenuButton className="focus:ring-hotPink-500 hover:ring-hotPink-500 flex rounded-full text-sm hover:ring-2 focus:outline-none focus:ring-2">
                    <span className="sr-only">Open user menu</span>

                    <div className={cx(darkBg ? 'text-white' : 'text-selphBlue-600', 'flex items-center')}>
                      <UserIcon
                        className={cx(
                          darkBg || bgColour === 'blue'
                            ? 'fill-white opacity-90'
                            : 'stroke-hotPink-500 fill-hotPink-500 border-hotPink-500',
                          'h-6 w-6 shrink-0 rounded-full border stroke-1 lg:h-8 lg:w-8',
                        )}
                      />
                    </div>
                  </MenuButton>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-20 mt-2 w-48 origin-top-right overflow-hidden rounded-b-xl bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          {({ active }) => (
                            <UI.Link
                              to={item.to}
                              color="none"
                              className={cx(
                                active ? 'bg-selphGrey-50' : '',
                                'text-selphGrey-800 block px-4 py-2 text-sm',
                              )}
                            >
                              {item.name}
                            </UI.Link>
                          )}
                        </MenuItem>
                      ))}

                      <hr />

                      <MenuItem>
                        {({ active }) => (
                          <a
                            href="#"
                            className={cx(
                              active ? 'bg-selphGrey-50' : '',
                              'text-selphGrey-800 block px-4 py-2 text-sm',
                            )}
                            onClick={(e) => {
                              e.preventDefault()
                              logout()
                              destroyBasket()
                            }}
                          >
                            Logout
                          </a>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              ) : (
                <UI.Link
                  color="none"
                  to="account"
                  className={cx(
                    darkBg ? 'text-white' : 'text-selphBlue-600',
                    'focus:ring-hotPink-500 hover:ring-hotPink-500 flex items-center rounded-full text-sm hover:ring-2 focus:outline-none focus:ring-2',
                  )}
                >
                  <UserCircleIcon className="h-6 w-6 shrink-0 rounded-full fill-transparent stroke-1 lg:h-8 lg:w-8" />
                </UI.Link>
              )}

              {/* Basket */}
              <div className="focus:ring-hotPink-500 hover:ring-hotPink-500 relative z-10 rounded-full p-1 hover:ring-2 focus:outline-none focus:ring-2">
                <UI.Link to="cart">
                  <ShoppingCartIcon
                    className={cx(darkBg ? 'text-white' : 'text-selphBlue-600', 'h-6 w-6 stroke-1 lg:h-8 lg:w-8')}
                    aria-hidden="true"
                  />

                  <div className="absolute -right-1 -top-1" title="Cart Items">
                    <div className="bg-hotPink-500 text-selphGrey-50 flex h-5 w-5 rounded-full text-sm">
                      <span className="m-auto my-auto">{numberOfLineItems}</span>
                    </div>
                  </div>
                </UI.Link>
              </div>

              {/* Algolia search */}
              <div className="z-10 flex items-center md:hidden">
                <button
                  type="button"
                  className={cx(
                    darkBg ? 'text-white' : 'text-selphBlue-600',
                    'focus:ring-hotPink-500 hover:ring-hotPink-500 rounded-full p-1 hover:ring-2 focus:outline-none focus:ring-2 md:hidden',
                  )}
                  onClick={() => setSearchOpen(true)}
                >
                  <span className="sr-only">Open searchbar</span>
                  <MagnifyingGlassIcon className="relative h-6 w-6 stroke-1" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>

          <UI.Breadcrumb size={{ default: 'sm', md: 'md' }} />
        </div>
      </div>
    </>
  )
}

export default Header

const userNavigation = [
  { name: 'My Dashboard', to: 'account' },
  { name: 'My Orders', to: 'accountOrders' },
  { name: 'My Results History ', to: 'accountResultsHistory' },
  { name: 'Account Settings', to: 'accountSettings' },
]

const navigation = {
  desktopPages: [
    { name: 'All Tests', to: 'tests' },
    { name: 'Gut Health', to: 'gutHealthTests' },
    { name: "Men's Health", to: 'mensHealthTests' },
    { name: "Women's Health", to: 'womensHealthTests' },
    { name: 'Metabolic Health', to: 'metabolicHealthTests' },
    { name: 'General Health', to: 'generalHealthTests' },
  ],
  mobilePages: [
    { name: 'Activate Test', to: 'activateKit' },
    { name: 'Learn', to: 'learn' },
    { name: 'Help', to: 'help' },
    { name: 'Contact Us', to: 'contact' },
  ],
}
