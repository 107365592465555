import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { useEffect, useState } from 'react'
//import { useRouter } from 'next/router'

export const ScrollingBanner = () => {
  const [emblaRef] = useEmblaCarousel({ loop: true }, [
    Autoplay({ playOnInit: true, delay: 10000, stopOnMouseEnter: true, stopOnInteraction: false }),
  ])
  //const { asPath } = useRouter()

  //const isAdmin = asPath.includes('admin')

  const [slides, setSlides] = useState<React.ReactNode[]>([])

  useEffect(() => {
    // eslint-disable-next-line react/jsx-key
    setSlides([])
  }, [])

  if (slides.length === 0) return null

  return (
    <div className="overflow-hidden" ref={emblaRef}>
      <div className="flex">
        {slides.map((slide, index) => (
          <div key={index} className="flex-[0_0_100%]">
            {slide}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ScrollingBanner
