import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { getParam } from '../../helpers/params'

export type ParamsContextType = {
  mcCampaignId?: string
  mcEmailId?: string
}

export const ParamsContext = createContext<ParamsContextType | undefined>(undefined)

export const ParamsProvider = ({ children }: { children: React.ReactNode }) => {
  const { query: params, isReady: isRouterReady } = useRouter()

  const [mcCampaignId, setMcCampaignId] = useState<ParamsContextType['mcCampaignId']>(undefined)
  const [mcEmailId, setMcEmailId] = useState<ParamsContextType['mcEmailId']>(undefined)

  useEffect(() => {
    if (!isRouterReady) return

    setMcCampaignId(getParam(params.mc_cid) || undefined)
    setMcEmailId(getParam(params.mc_eid) || undefined)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRouterReady])

  return <ParamsContext.Provider value={{ mcCampaignId, mcEmailId }}>{children}</ParamsContext.Provider>
}

export const useParams = () => {
  const context = useContext(ParamsContext)

  if (context === undefined) {
    throw new Error('useParams must be used within a ParamsProvider')
  }
  return context
}

export default useParams
