import { Highlight, useInstantSearch } from 'react-instantsearch'
import { Hit as AlgoliaHit } from 'instantsearch.js/es/types'
import ProductLink from '../product/product-link'
import { ProductType } from '../../gql'
import { AlgoliaSearchRecord } from './search-results'
import * as UI from '@/ui'

export type HitProps = {
  hit: AlgoliaHit & AlgoliaSearchRecord
  mobileSearch?: { searchOpen: boolean; setSearchOpen: (v: boolean) => void }
}

export const Hit = ({ hit, mobileSearch }: HitProps) => {
  const { setIndexUiState } = useInstantSearch()

  const handleClick = () => {
    setIndexUiState((prevState) => ({
      ...prevState,
      query: '',
    }))

    mobileSearch?.searchOpen && mobileSearch.setSearchOpen(false)
  }

  return (
    <article className="pl-2 md:pl-0 pr-14 md:pr-0 md:bg-white md:rounded-md hover:bg-slate-100 transition-colors duration-200 border-b border-slate-100">
      <ProductLink
        slug={hit.slug}
        type={hit.type as ProductType}
        onClick={handleClick}
        color="none"
        className="block w-full py-3 sm:px-2 md:px-6"
      >
        <div className="flex items-center gap-3 md:gap-4">
          <div className="flex-shrink-0">
            <UI.Image
              src={`/images/product/${hit.featuredImage.src}`}
              alt={hit.featuredImage.name}
              priority
              width={48}
              height={48}
              className="hidden [@media(min-width:420px)]:block md:w-[56px] md:h-[56px] object-cover rounded-lg ring-1 ring-hotPink-500"
            />
          </div>

          <div className="flex-1 min-w-0">
            <UI.Block gap="xs" className="w-full">
              <UI.Paragraph weight="semiBold" color="darkGrey" className="line-clamp-1">
                <Highlight
                  hit={hit}
                  attribute="name"
                  classNames={{
                    highlighted: 'text-selphBlue-300',
                    nonHighlighted: 'text-selphBlue-500',
                  }}
                />
              </UI.Paragraph>

              <UI.Paragraph color="lightGrey" className="line-clamp-2 text-sm">
                {hit.description}
              </UI.Paragraph>

              <UI.Paragraph className="text-sm font-medium mt-1 text-selphGrey-700">
                <UI.Currency value={hit.price} />
              </UI.Paragraph>
            </UI.Block>
          </div>
        </div>
      </ProductLink>
    </article>
  )
}

export default Hit
