import useStorage from '../use-storage'

export type Engagement = {
  hasEngaged: boolean
  recentEmail?: string
}

export const useEngagement = () => {
  const { getItem, setItem } = useStorage()

  if (!getItem('engagement', 'local')) {
    setItem('engagement', { hasEngaged: false }, 'local')
  }

  const setEngagement = (newEngagement: { hasEngaged?: boolean; recentEmail?: string }) => {
    const engagement = getItem<Engagement>('engagement', 'local')

    setItem('engagement', { ...engagement, ...newEngagement }, 'local')
  }

  const hasEngaged = getItem<Engagement>('engagement', 'local')?.hasEngaged
  const recentEmail = getItem<Engagement>('engagement', 'local')?.recentEmail

  return {
    hasEngaged,
    recentEmail,
    setEngagement,
  }
}
