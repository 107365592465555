import FiveStarsIcon from '/public/images/tp_rating_5-star.svg?svgr'
import TPLogoWhite from '/public/images/TP-Logo-White.svg?svgr'
import TPLogo from '/public/images/TP-Logo.svg?svgr'
import { TrustPilotData } from './trustpilot-box'

export const TPMicroHeader = ({ trustData, darkBg }: { trustData: TrustPilotData; darkBg?: boolean }) => {
  return (
    <div className="w-fit font-helvetica flex justify-center items-center">
      <a target="_blank" href={trustData.url} className="w-full flex justify-center items-center">
        <span
          className={`text-sm lg:text-base font-medium ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } flex self-center mr-1.5`}
        >
          {trustData.label}
        </span>

        <span title={`${trustData.rating} out of five star rating on Trustpilot`}>
          <FiveStarsIcon className=" w-[92px] md:w-[108px]" />
          <span className="sr-only">Five Star Review</span>
        </span>

        <div
          className={`hidden min-[430px]:max-md:flex lg:flex text-sm w-[114px] ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } tracking-normal mx-[7px] pt-0.5 items-baseline justify-between`}
        >
          <span className="font-medium">{trustData.reviews.toLocaleString()}</span>
          <span className="font-medium"> reviews on</span>
        </div>

        <span title="Trustpilot">
          {darkBg ? (
            <TPLogoWhite width="80" className="mx-auto hidden min-[430px]:max-md:block lg:block" />
          ) : (
            <TPLogo width="80" className="mx-auto hidden min-[430px]:max-md:block lg:block" />
          )}
          <span className="sr-only">Trustpilot Logo</span>
        </span>

        <span
          className={`hidden min-[300px]:max-[429px]:block md:max-lg:block ml-1 text-sm font-medium ${
            darkBg ? 'text-white' : 'text-selphGrey-900'
          } `}
        >
          {trustData.reviews.toLocaleString()} reviews
        </span>
      </a>
    </div>
  )
}

export default TPMicroHeader
