import CurrencyFormat from 'react-currency-format'

export const Currency = ({
  value,
  inputUnit = 'pence',
  id,
}: {
  value: number
  inputUnit?: 'pence' | 'pounds'
  id?: string
}) => {
  if (inputUnit === 'pence') {
    value /= 100
  }
  return (
    <CurrencyFormat
      value={value}
      displayType={'text'}
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator=","
      prefix={'£'}
      id={id}
    />
  )
}

Currency.displayName = 'Currency'

export default Currency
