import Link from 'next/link'

export type LinkPaginationProps = {
  articlesPerPage: number
  totalArticles: number
  currentPage: number
  parentId?: string
  paginate?: (number: number) => void
  pageToHref: (page: number) => string
}

export const LinkPagination = ({
  articlesPerPage,
  totalArticles,
  currentPage,
  paginate,
  pageToHref,
}: LinkPaginationProps) => {
  const pageNumbers = []
  const totalPages = Math.ceil(totalArticles / articlesPerPage)

  let startPage = Math.max(1, currentPage - 2)
  let endPage = Math.min(startPage + 4, totalPages)

  if (currentPage <= 3) {
    endPage = Math.min(5, totalPages)
  }

  if (currentPage >= totalPages - 2) {
    startPage = Math.max(totalPages - 4, 1)
  }

  pageNumbers.push('< Prev')

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  pageNumbers.push('Next >')

  const disablePrev = (number: string) => {
    return number === '< Prev' && currentPage === 1
  }

  const disableNext = (number: string) => {
    return number === 'Next >' && currentPage === totalPages
  }

  const handleClick = (pageNumber: number) => {
    typeof paginate === 'function' && paginate(pageNumber)
  }

  if (totalPages === 1) return null

  return (
    <nav>
      <ul className="flex justify-center text-lg text-selphBlue-500 ">
        {pageNumbers.map((number, index) => (
          <li key={index} className="mx-3 font-semibold">
            {typeof number === 'number' ? (
              <Link
                href={pageToHref(number)}
                onClick={() => {
                  handleClick(number)
                }}
                className={`hover:text-hotPink-500 ${
                  number === currentPage &&
                  'text-lg border-2 border-hotPink-500 px-1.5 rounded-md hover:text-selphBlue-500 pointer-events-none'
                  // pointer-events-none is a reasonable way to implement 'disabled' for <a> tags
                }`}
                aria-label={`Articles page ${number}`}
                aria-disabled={number === currentPage}
              >
                {number}
              </Link>
            ) : (
              <Link
                href={pageToHref(number === 'Next >' ? currentPage + 1 : currentPage - 1)}
                onClick={() => {
                  handleClick(number === 'Next >' ? currentPage + 1 : currentPage - 1)
                }}
                className={`hover:text-hotPink-500 ${
                  (disablePrev(number) || disableNext(number)) &&
                  'opacity-50 hover:text-selphBlue-500 pointer-events-none'
                  // pointer-events-none is a reasonable way to implement 'disabled' for <a> tags
                }`}
                aria-label={`${(number === 'Next >' && 'Next') || 'Previous'} page`}
                aria-disabled={disablePrev(number) || disableNext(number)}
              >
                {number}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

LinkPagination.displayName = 'Pagination'

export default LinkPagination
