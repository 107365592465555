import { gql } from '@apollo/client'
import { NextSeo } from 'next-seo'
import { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/router'
import { client } from '../../services/apollo-client'
import CategoriesMarkdownData from '../../components/learn/categories.json'
import CircleBlur from '../../public/images/circleBlur.svg?svgr'
import CategoryList, { CategoryProps } from '../../components/learn/category-list'
import BlogWhatsNew from '../../components/learn/blog-whats-new'
import TrendingArticlesList from '../../components/learn/trending-articles-list'
import { resolveArticlesBySlugs } from '../../helpers/blogs'
import LearnLayoutHeader from '../../components/layout/header'
import { LinkPagination } from '../../components/ui/link-pagination'
import BlogCard from '../../components/learn/blog-card'
import { BlogCardFragment, BlogCardFragmentDoc, LearnHubPageDataQuery, LearnHubPageDataQueryVariables } from '@/gql'
import * as UI from '@/ui'

export type LearnHubArticle = BlogCardFragment & { summary: string }

const articlesPerPage = 24

type LearnHubProps = {
  articles: LearnHubArticle[]
  latestArticle: LearnHubArticle
  trendingArticles: BlogCardFragment[]
  allArticleCount: number
  categories: CategoryProps[]
  page: number
}

export const LearnHub = ({
  latestArticle,
  trendingArticles,
  articles,
  categories,
  page,
  allArticleCount,
}: LearnHubProps) => {
  const { asPath } = useRouter()
  const canonical = `https://www.selph.co.uk${asPath.split('?')[0].replace(/\/$/, '')}?page=${page}`

  return (
    <>
      <NextSeo
        title="Learn Hub"
        description="Become the expert in your own health with health and wellbeing articles and guides written by the doctors and scientists at Selph."
        canonical={canonical}
      />

      <UI.Block gap="large" className="relative mb-28 lg:mb-32">
        <CircleBlur className="absolute top-0 left-0 -translate-x-1/3 translate-y-1/4 w-[800px] h-[800px] md:w-[1500px] md:h-[1500px]" />

        <CircleBlur className="absolute top-1/2 right-0 translate-x-1/3 w-[800px] h-[800px] md:w-[1500px] md:h-[1500px]" />

        <UI.Block gap={{ default: 'large', md: 'xxl' }} className="relative max-w-7xl mx-auto">
          {page === 1 && (
            <>
              <UI.Block gap="xl">
                <UI.Heading
                  color="midBlue"
                  size={{ default: 'small', md: 'medium' }}
                  weight="regular"
                  className="text-center"
                >
                  <h2>Health Topics</h2>
                </UI.Heading>

                <CategoryList categories={categories} />
              </UI.Block>

              <UI.Divider color="pink" />

              <UI.Block gap="xl" className="max-w-5xl mx-auto">
                <UI.Heading
                  color="midBlue"
                  size={{ default: 'small', md: 'medium' }}
                  weight="regular"
                  className="text-center"
                >
                  <h2>What&apos;s New</h2>
                </UI.Heading>

                <BlogWhatsNew blog={latestArticle} />
              </UI.Block>

              <UI.Divider color="pink" />

              <UI.Block gap="xl">
                <UI.Heading
                  color="midBlue"
                  size={{ default: 'small', md: 'medium' }}
                  weight="regular"
                  className="text-center"
                >
                  <h2>Trending Articles</h2>
                </UI.Heading>
                <TrendingArticlesList articles={trendingArticles} />
              </UI.Block>

              <UI.Divider color="pink" />
            </>
          )}
          <UI.Block gap="xl">
            <UI.Heading
              color="midBlue"
              size={{ default: 'small', md: 'medium' }}
              weight="regular"
              className="text-center"
            >
              <h2>All Articles - Page {page}</h2>
            </UI.Heading>

            <UI.Block gap="medium" id="LearnHubAllArticles">
              <div className="flex flex-wrap gap-10 justify-center">
                {articles.map((article) => (
                  <BlogCard blog={article} key={article.title} showBadge />
                ))}
              </div>

              <LinkPagination
                articlesPerPage={articlesPerPage}
                totalArticles={allArticleCount}
                currentPage={page}
                pageToHref={(p) => `/learn?page=${p}#LearnHubAllArticles`}
              />
            </UI.Block>
          </UI.Block>
        </UI.Block>
      </UI.Block>
    </>
  )
}

LearnHub.layoutProps = () => {
  const headerData = {
    title: 'Learn Hub',
    description: [
      "If you want to become an expert in your own health, you'll need answers to some important questions. You'll find those answers right here.",
      'Our medical experts review the evidence and dissect the science to separate fad from fiction and give you - in plain English - actionable insights you can trust.',
    ],
    // searchButton: {
    //   label: 'Search',
    //   placeholder: 'Search the Learn Hub',
    //   action: () => {
    //     alert('Searching articles')
    //   },
    // },
  }

  return {
    header: <LearnLayoutHeader headerData={headerData} />,
  }
}

export default LearnHub

const trendingSlugs = [
  '7-evidenced-based-actions-you-can-take-to-prevent-bowel-cancer-or-pick-it-up-earlier',
  'when-should-you-start-screening-for-bowel-cancer',
  'what-is-hba1c-and-whats-the-hba1c-normal-range',
]

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const response = await client.query<LearnHubPageDataQuery, LearnHubPageDataQueryVariables>({
    query: query,
    variables: {
      trendingSlugs,
    },
  })

  const trendingArticles = resolveArticlesBySlugs({
    slugs: trendingSlugs,
    articles: response.data.trendingArticles,
  })

  const categories = Object.values(CategoriesMarkdownData) as CategoryProps[]

  const allArticles = response.data.allArticles
  const page = Number(context.query.page) || 1
  const indexOfLastArticle = page * articlesPerPage
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage

  return {
    props: {
      articles: allArticles.slice(indexOfFirstArticle, indexOfLastArticle),
      latestArticle: allArticles[0],
      trendingArticles,
      allArticleCount: allArticles.length,
      categories,
      page,
    },
  }
}

export const hoverStyles =
  'overflow-clip shadow-[1.5px_1.5px_0_1.5px_#0D4780] transform transition-transform duration-200 ease-in-out hover:-translate-y-4 hover:shadow-[1.5px_1.5px_0_1.5px_#E22E89]'

const query = gql`
  query LearnHubPageData($trendingSlugs: [String!]!) {
    allArticles {
      __typename
      ...BlogCard
      summary
    }

    trendingArticles: articlesBySlug(slugs: $trendingSlugs) {
      __typename
      ...BlogCard
    }
  }

  ${BlogCardFragmentDoc}
`
