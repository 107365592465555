import { liteClient as algoliasearch } from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Configure } from 'react-instantsearch'
import type { SearchClient } from 'instantsearch.js'
import 'instantsearch.css/themes/satellite.css'
import SearchResults from './search-results'
import * as UI from '@/ui'

const ALGOLIA_APPLICATION_ID = process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID
const ALGOLIA_SEARCH_API_KEY = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY

if (!ALGOLIA_APPLICATION_ID || !ALGOLIA_SEARCH_API_KEY) {
  console.error('Missing search connection data')
}

const baseSearchClient = algoliasearch(ALGOLIA_APPLICATION_ID || '', ALGOLIA_SEARCH_API_KEY || '')
const indexName = 'products-index'

const searchClient: SearchClient = {
  ...baseSearchClient,
  search(requests) {
    if (requests.every(({ params }) => !params?.query || params.query.length < 2)) {
      // Return empty results if the query is empty or has less than 2 characters
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          processingTimeMS: 0,
          exhaustiveNbHits: true,
          query: '',
          params: '',
        })),
      })
    }

    return baseSearchClient.search(requests)
  },
}

export const AlgoliaSearch = ({
  mobileSearch,
}: {
  mobileSearch?: { searchOpen: boolean; setSearchOpen: (v: boolean) => void }
}) => {
  if (!searchClient) {
    console.error('Missing search connection data')
    return null
  }

  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <Configure hitsPerPage={3} />

      <UI.Block className="px-10 md:px-0 md:max-w-40 lg:max-w-56 max-md:pt-1 w-full">
        <SearchBox
          placeholder="Search our tests"
          queryHook={queryHook}
          classNames={{
            form: 'rounded-lg h-8',
            input:
              'md:w-40 lg:max-w-56 lg:w-full pl-10 border-selphBlue-300 ring-1 ring-selphBlue-300 rounded-lg placeholder-selphGrey-500 focus:outline-none focus:border-hotPink-500 focus:ring-hotPink-500 focus:ring-1',
            resetIcon: 'w-3 h-3',
          }}
          autoFocus={mobileSearch?.searchOpen} // applicable only on mobile breakpoint ("sm")
        />

        <div className="absolute md:relative w-full top-20 md:top-12 md:-ml-40 ">
          <SearchResults mobileSearch={mobileSearch} />
        </div>
      </UI.Block>
    </InstantSearch>
  )
}

export default AlgoliaSearch

let timerId: ReturnType<typeof setTimeout>

function queryHook(query: string, search: (v: string) => void) {
  if (timerId) {
    clearTimeout(timerId)
  }

  timerId = setTimeout(() => search(query.trim()), 400)
}
